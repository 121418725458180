<template>
    <!-- 产品 -->
    <div class="digital-product">
        <a-card>
            <template #title>
                <a-space>
                    <a-button type="primary" @click="showProduct(null)" v-has="{action:'wxapp_storage_product_add',plat:0}">
                        <i class="ri-add-line ri-btn"></i>新增产品
                    </a-button>
                    <a-button @click="showUnit" v-has="{action:'wxapp_storage_product_unit_list',plat:0}">计量单位</a-button>
                </a-space>
            </template>
            <template #extra>
                <a-input-search
                    v-model:value="pdState.search.key"
                    placeholder="产品名称"
                    enter-button
                    style="width:400px"
                    @search="getProduct(1,pdState.limit)"
                />
            </template>
            <a-table :pagination="false" rowKey="id" :data-source="pdState.list" :columns="[
                {title:'产品编号',dataIndex:'id'},
                {title:'图片',dataIndex:'images',slots:{customRender:'images'}},
                {title:'产品名称',dataIndex:'name'},
                {title:'产品条码',dataIndex:'bar_code'},
                {title:'首选仓库',dataIndex:'depot.name'},
                {title:'当前库存',dataIndex:'stock'},
                {title:'计量单位',dataIndex:'unit.name'},
                {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                {title:'备注',dataIndex:'remark'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: pdState.pks, onChange:(e)=>{pdState.pks = e}  }">
                <template #images="{record}">
                    <div v-if="record.images && record.images.length > 0">
                        <a-image :src="record.images[0]"  width="50px"></a-image>
                    </div>
                </template>
                <template #status="{record}">
                    <a-tooltip :title="record.status === 1 ?'切换状态为禁用':'切换状态为启用'">
                        <a-tag class="pt" :color="record.status ?'#00CC66':'#FF0066'"
                               @click="changeProductStatus([record.id],record.status === 1 ?0:1)">
                            {{record.status === 1 ?'启用':'禁用'}}
                        </a-tag>
                    </a-tooltip>
                </template>
                <template #action="{record}">
                    <kd-button title="编辑" icon="ri-edit-line" @click="showProduct(record)"
                               v-has="{action:'wxapp_storage_product_add',plat:0}"></kd-button>
                    <kd-button title="删除" icon="ri-delete-bin-2-line" type="danger"
                               v-has="{action:'wxapp_storage_product_delete',plat:0}"
                               @click="deleteProduct([record.id])">
                    </kd-button>
                </template>

                <template #expandedRowRender="{ record }">
                    <div style="padding: 12px 0">
                        <a-table :pagination="false" rowKey="id" :data-source="record.sku" :columns="[
                            {title:'规格型号',dataIndex:'model'},
                            {title:'零售价',dataIndex:'retail_price'},
                            {title:'批发价',dataIndex:'wholesale_price'},
                            {title:'VIP会员价',dataIndex:'vip_price'},
                            {title:'折扣率1(%)',dataIndex:'discount_rate1'},
                            {title:'折扣率2(%)',dataIndex:'discount_rate2'},
                            {title:'质保',dataIndex:'early_warning'},
                            {title:'库存上下限',dataIndex:'waring_limit',slots:{customRender:'waring_limit'}},
                            {title:'备注',dataIndex:'remark'},
                        ]">
                            <template #waring_limit="{record}">
                                {{record.upper_warning_limit}}—{{record.lower_waring_limit}}
                            </template>
                        </a-table>
                    </div>

                </template>

            </a-table>
            <kd-pager :page-data="pdState" :event="getProduct">
                <a-space>
                    <a-button @click="changeProductStatus(pdState.pks,0)"
                              v-has="{action:'wxapp_storage_product_status',plat:0}">
                        禁用
                    </a-button>
                    <a-button @click="changeProductStatus(pdState.pks,1)"
                              v-has="{action:'wxapp_storage_product_status',plat:0}">
                        启用
                    </a-button>
                    <a-button @click="deleteProduct(pdState.pks)"
                              v-has="{action:'wxapp_storage_product_delete',plat:0}">
                        删除
                    </a-button>
                </a-space>
            </kd-pager>
        </a-card>

        <a-modal title="添加产品" v-model:visible="epState.show" @ok="saveProduct" width="1000px" :z-index="99">
            <a-form :label-col="{span:6}" :wrapper-col="{span:18}">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <a-form-item label="产品名称" required>
                            <a-input v-model:value="epState.form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="计量单位">
                            <a-select v-model:value="epState.form.unit_id" placeholder="计量单位">
                                <a-select-option :value="0">选择计量单位</a-select-option>
                                <a-select-option v-for="(item,index) in uState.list" :key="index" :value="item.id">
                                    {{item.name}}
                                </a-select-option>
                                <template #dropdownRender="{ menuNode: menu }">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0" />
                                    <div style="padding: 4px 8px; cursor: pointer"
                                         @mousedown="e => e.preventDefault()"
                                         @click="showEditUnit(null)"
                                    >
                                        <i class="ri-add-line ri-top"></i>添加计量单位
                                    </div>
                                </template>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="质保预警">
                            <a-input v-model:value="epState.form.early_warning" addon-after="天"></a-input>
                        </a-form-item>
                        <a-form-item label="产品图片" >
                            <kd-img-select :mutil="true" :src="epState.form.images"
                                           @success="e=>epState.form.images = e">
                            </kd-img-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="产品条码" required>
                            <a-input v-model:value="epState.form.bar_code"></a-input>
                        </a-form-item>
                        <a-form-item label="首选仓库">
                            <a-select v-model:value="epState.form.depot_id" placeholder="首选仓库">
                                <a-select-option :value="0">选择首选仓库</a-select-option>
                                <a-select-option v-for="(item,index) in pdState.enterPot"
                                                 :key="index" :value="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="库存预警">
                            <a-space>
                                <a-input v-model:value="epState.form.upper_warning_limit"
                                         style="width: 150px;" addon-after="上限">
                                </a-input>
                                ——
                                <a-input v-model:value="epState.form.lower_waring_limit"
                                         style="width: 150px;" addon-after="下限">
                                </a-input>
                            </a-space>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-input v-model:value="epState.form.remark"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-table :pagination="false" rowKey="id" :data-source="epState.form.sku" :columns="[
                            {title:'规格型号',dataIndex:'model'},
                            {title:'零售价',dataIndex:'retail_price'},
                            {title:'批发价',dataIndex:'wholesale_price'},
                            {title:'VIP会员价',dataIndex:'vip_price'},
                            {title:'折扣率1(%)',dataIndex:'discount_rate1'},
                            {title:'折扣率2(%)',dataIndex:'discount_rate2'},
                            {title:'质保',dataIndex:'early_warning'},
                            {title:'库存上下限',dataIndex:'waring_limit',slots:{customRender:'waring_limit'}},
                            {title:'备注',dataIndex:'remark'},
                            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                        ]">
                            <template #waring_limit="{record}">
                                {{record.upper_warning_limit}}—{{record.lower_waring_limit}}
                            </template>
                            <template #action="{record,index}">
                                <a-button type="link" @click="showProductSpec(record,index)">编辑</a-button>
                                <a-button type="link" @click="deleteProductSpec(index)">删除</a-button>
                            </template>
                        </a-table>
                    </a-col>
                    <a-col class="product-add-spec" @click="showProductSpec(null,0)"><i class="ri-add-line ri-top"></i>添加规格型号</a-col>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal title="添加产品规格" v-model:visible="epState.specShow" @ok="sureAddSpec" width="700px" :zIndex="999">
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}" v-if="epState.specForm">
                <a-form-item label="规格类型">
                    <a-input v-model:value="epState.specForm.model"></a-input>
                </a-form-item>
                <a-form-item label="零售价">
                    <a-input v-model:value="epState.specForm.retail_price" addon-after="元"></a-input>
                </a-form-item>
                <a-form-item label="批发价">
                    <a-input v-model:value="epState.specForm.wholesale_price" addon-after="元"></a-input>
                </a-form-item>
                <a-form-item label="vip价">
                    <a-input v-model:value="epState.specForm.vip_price" addon-after="元"></a-input>
                </a-form-item>
                <a-form-item label="折扣率1">
                    <a-input v-model:value="epState.specForm.discount_rate1" addon-after="%"></a-input>
                </a-form-item>
                <a-form-item label="折扣率2">
                    <a-input v-model:value="epState.specForm.discount_rate2" addon-after="%"></a-input>
                </a-form-item>
                <a-form-item label="质保">
                    <a-input v-model:value="epState.specForm.early_warning" addon-after="天"></a-input>
                </a-form-item>
                <a-form-item label="上下限">
                    <a-space>
                        <a-input v-model:value="epState.specForm.upper_warning_limit"
                                 style="width: 150px;" addon-after="上限">
                        </a-input>
                        ——
                        <a-input v-model:value="epState.specForm.lower_waring_limit"
                                 style="width: 150px;"  addon-after="下限">
                        </a-input>
                    </a-space>
                </a-form-item>
                <a-form-item label="备注">
                    <a-input v-model:value="epState.specForm.remark"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal title="添加计量单位" v-model:visible="euState.show" @ok="saveUnit" :z-index="9999">
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}" v-if="euState.form">
                <a-form-item label="名称">
                    <a-input v-model:value="euState.form.name"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal title="计量单位" v-model:visible="uState.show" :footer="null" width="700px">
            <a-table :pagination="false" rowKey="id" :data-source="uState.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'产品名称',dataIndex:'name'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
                <template #action="{record}">
                    <kd-button title="编辑"
                               icon="ri-edit-line"
                               @click="showEditUnit(record)"
                               v-has="{action:'wxapp_storage_product_unit_add',plat:0}">
                    </kd-button>
                    <kd-button title="删除"
                               icon="ri-delete-bin-2-line"
                               type="danger"
                               @click="changeUnitStatus(record)"
                               v-has="{action:'wxapp_storage_product_unit_delete',plat:0}"
                    ></kd-button>
                </template>
            </a-table>
        </a-modal>

    </div>

</template>
<script>
import {useProduct, useEditProduct, useEditUnit, useUnit} from '@/models/addons/storage'
export default {
    components:{
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    setup() {
        //产品列表
        let { pdState,getProduct,deleteProduct,changeProductStatus ,exportProduct ,importProduct} = useProduct()

        //产品单位
        let { uState ,getUnit ,showUnit,changeUnitStatus  } = useUnit()
        let {
            epState,showProduct,saveProduct,sureAddSpec,showProductSpec,deleteProductSpec
        } = useEditProduct(pdState,getProduct )

        //编辑产品信息
        let { euState,showEditUnit,saveUnit } = useEditUnit(getUnit)

        getUnit()

        return{
            pdState,getProduct,deleteProduct,changeProductStatus ,exportProduct,importProduct,
            epState,showProduct,saveProduct,sureAddSpec,showProductSpec,deleteProductSpec,
            euState,showEditUnit,saveUnit,
            uState ,getUnit ,showUnit,changeUnitStatus
        }
    },
}
</script>
<style scoped lang="scss">
.product-add-spec{
    width: 100%;
    text-align: center;
    border: 1px dashed #bfbfbf;
    cursor: pointer;
}
</style>
